<template>
  <div>
    <ek-dialog
      ref="dialog"
      :isEdit="!!clientForm.id"
      @ok="addLocaleClient()"
      @delete="deleteClient(clientForm.id)"
    >
      <template #header>
        <h4>{{ clientForm.id ? "تعديل العميل" : "اضافة عميل" }}</h4>
      </template>
      <template #body>
        <ValidationObserver ref="form">
          <ek-input-text
            placeholder="ادخل اسم العميل"
            name="اسم العميل"
            v-model="clientForm.name"
            label="اسم العميل"
            :rules="[{ type: 'required' }]"
          >
          </ek-input-text>

          <ek-input-image
            required
            title="انقر  لتحميل صورة "
            name="صورة العميل"
            ref="imgInput"
            :value="
              clientForm.image
                ? $store.getters['app/domainHost'] + '/' + clientForm.image
                : ''
            "
            @input="clientForm.file = $event"
            label=" صورة العميل"
          >
          </ek-input-image>
        </ValidationObserver>
      </template>
    </ek-dialog>
    <ek-table
      title="العملاء"
      class="mt-2"
      @delete-selected="deleteMultiClients"
      @details="showDialog"
      @plus="showDialog"
      :items="clientsList"
      label="العملاء"
      isPlus
      :columns="cols"
    >
      <template slot="items.image" slot-scope="{ props }">
        <b-avatar
          size="4rem"
          :src="$store.getters['app/domainHost'] + '/' + props.row.image"
          rounded="circle"
          variant=""
        >
        </b-avatar>
      </template>
    </ek-table>
  </div>
</template>

<script>
import EkDialog from "@/EK-core/components/EK-dialog";
import EkInputText from "@/EK-core/components/EK-forms/EK-input-text";
import EkInputImage from "@/EK-core/components/EK-forms/EK-input-image";
import EkTable from "@/EK-core/components/EK-table";
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  components: {
    EkTable,
    EkDialog,
    EkInputImage,
    EkInputText,
    ValidationObserver,
  },
  data: () => ({
    cols: [
      {
        label: " العميل",
        field: "image",
      },
      {
        label: "اسم العميل",
        field: "name",
      },
      {
        label: "تعديل",
        field: "details",
      },
    ],
    value: [{ name: "asd", id: 1 }],
  }),
  computed: {
    ...mapState({
      clientForm: (state) => state.settings.clientDto,
      clients: (state) => state.settings.clients,
    }),
    isEdit() {
      return !!this.clientForm.id;
    },
    ...mapGetters(["clientsList"]),
  },
  methods: {
    ...mapActions([
      "getClients",
      "addClient",
      "updateClient",
      "deleteClient",
      "deleteMultiClients",
    ]),

    showDialog(data) {
      if (data)
        this.$store.commit("Set_Client_Dto", {
          name: data.row.name,
          file: data.row.file,
          image: data.row.image,
          id: data.row.id,
        });
      else this.$store.commit("Set_Client_Dto");
      this.$refs.dialog.open();
    },
    addLocaleClient() {
      this.$refs.form.validate().then((suc) => {
        console.log(suc);
        if (suc) {
          const formData = new FormData();
          formData.append("file", this.clientForm.file);
          formData.append("name", this.clientForm.name);
          if (this.isEdit) {
            formData.append("id", this.clientForm.id);
            formData.append("image", this.clientForm.image);
            this.updateClient(formData);
          } else if (this.clientForm.file) this.addClient(formData);

          this.resetDialog();
        }
      });
    },
    resetDialog() {
      this.$refs.dialog.close();
      this.$refs.form.reset();
      this.$refs.imgInput.clear();

    },
  },
  created() {
    this.getClients();
    this.$store.commit("Set_Search_Keys", ["name"]);
  },
};
</script>
